<template>
  <div class="flex flex-col items-stretch gap-5">
    <div class="relative bg-white border border-dashed border-primary rounded-lg block">
      <div
        class="absolute w-full h-full flex flex-col items-center justify-center gap-1 pointer-events-none"
      >
        <UploadIcon class="h-5 w-5 mb-1 text-primary" fill="currentColor" />
        <span class="text-primary text-xxs">Upload your Logo File</span>
        <span class="text-base-content text-xxs">.jpg, .png, .ai, .svg or .pdf & others</span>
      </div>
      <VueFilePond
        class="w-full"
        name="file"
        ref="pond"
        @removefile="removeFile"
        v-bind="computedConfig"
        v-bind:server="imageUploadServer"
      />
    </div>
    <Button
      class="w-full"
      text="Browse"
      type="primary"
      @click="browseFile"
    />
  </div>
</template>

<script>
import VueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import axios from "@/axios";

import UploadIcon from '@/assets/icons/svg/upload-2.svg';
import Button from "@/components/button";

export default {
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    tenant_id: {
      type: String,
    },
  },
  components: {
    VueFilePond: VueFilePond(
      FilePondPluginFileValidateType,
      FilePondPluginImagePreview,
      FilePondPluginGetFile,
    ),
    UploadIcon,
    Button,
  },
  data() {
    return {
      config: {
        'label-idle': "",
        'credits': 'false',
        'allow-multiple': false,
        'accepted-file-types': "image/*",
        className: 'h-full',
      },
      token: null,
      imageUploadServer: {
        process: this.addFile,
      },
      openImageURL: "",
      file: '', // used for handling current value
    };
  },
  computed: {
    computedConfig() {
      return {...this.config, ...this.settings};
    },
  },
  methods: {
    browseFile() {
      this.$refs.pond.browse();
    },
    // is used for adding new files
    async addFile(fieldname, file, metadata, load, error, progress, abort) {
      const token = this.$route.query.access_token;
      console.log(fieldname, metadata, error, abort)

      let formData = new FormData();

      formData.append('file', file);

      try {
        const response = await axios({
          url: `/tenant/logo/${this.tenant_id}`,
          method: "post",
          data: formData, 
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          },
          onUploadProgess:(e) => {
            progress(e.lengthComputable, e.loaded, e.total)
          }
        })
        this.file = response.data.data.logo_url;
        this.$emit('input', this.file);
        load(response.data.data.logo_url)
      } catch (error) {
        console.log('file loading error', error);
        this.$refs.pond.removeFiles();
      }
    },
    removeFile(error, removedFile) {
      if (error) console.log('error while file removing', error);
      console.log('remove file', removedFile);
      this.file = '';
      this.$emit('input', this.file);
    }

  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .filepond--root {
    margin-bottom: 0;
    .filepond--panel, .filepond--panel-root {
      @apply bg-transparent;
    }
  }
}
</style>